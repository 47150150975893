@import url('https://rsms.me/inter/inter.css');

html {
    font-family: 'Inter', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@supports (font-variation-settings: normal) {
    html {
        font-family: 'Inter var', sans-serif;
    }
}

body {
    margin: 0;
    padding: 0;
    font-size: 13px;
}

div {
    box-sizing: border-box;
}

input {
    padding: 0;
    outline: none;
    border: none;
    font-family: inherit;
    color: inherit;
    font-size: inherit;
    display: block;
    min-width: none;
    width: 100%;
    background: transparent;
    letter-spacing: inherit;
    text-align: inherit;
}

button {
    font-family: inherit;
    color: inherit;
    font-size: inherit;
    background: white;
    padding: 0;
    outline: none;
    border: none;
}

* {
    box-sizing: border-box;
}

a {
    color: inherit;
    text-decoration: none;
}

._loading-icon {
    margin-left: 8px;
    height: 14px;
    animation: spin 1s infinite linear;
    pointer-events: none;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

::-webkit-scrollbar {
    width: 7px;
    height: 7px;
}

::-webkit-scrollbar-track {
    background: white;
    border: 1px solid #d3d7de;
}

::-webkit-scrollbar-thumb {
    background: #e2e4e9;
    border-radius: 20px;
}

::-webkit-scrollbar-thumb:hover {
    background: #cacfd8;
}

::-webkit-scrollbar-track:horizontal {
    border-right: 1px solid white;
}

::-webkit-scrollbar-track:vertical {
    border-bottom: 1px solid white;
}

::-webkit-scrollbar-corner {
    border-bottom: 1px solid #d3d7de;
    border-right: 1px solid #d3d7de;
    background: white;
    border-radius: 0px 0px 5px 0px;
}

/* REACT TOASTIFY */

.Toastify__toast {
    min-height: 0px;
    padding: 4px;
    padding-bottom: 8px;
}

.Toastify__toast--success {
    background: #00a51e;
}

.Toastify__toast--error {
    background: #de0000;
}

.Toastify__close-button {
    transform: scale(0.8);
    opacity: 0.5;
}

.Toastify__progress-bar {
    bottom: 4px;
    height: 2px;
    background: rgba(255, 255, 255, 0.5);
}

/* DATE PICKER */

/* .react-datepicker-popper {
    z-index: 999;
}

.react-datepicker {
    margin-top: 40px;
} */

div.__custom_select__control {
    border-radius: 4px;
    border: none;
}

.react-datepicker-popper {
    z-index: 99;
}
